


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Button, ViewItem } from '@/models'
import { ButtonFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import resolveLink from '@/utils/route/resolveLink'
import openLink from '@/utils/route/openLink'
import _snakeCase from 'lodash/snakeCase'
import _pick from 'lodash/pick'
import { GraphQLError } from 'graphql'

@Component({
  components: {
    Loading,
    ComponentHeader
  },
  apollo: {
    button: {
      query: gql`query getButton ($buttonId : ID) {
        button(buttonId: $buttonId) {
          ...Button
        }
      }
      ${ButtonFragment}
      `,
      variables () {
        return {
          buttonId: this.componentId
        }
      }
    }
  }
})
export default class ButtonView extends Vue {
  @Prop({ type: String }) environmentId !: string
  @Prop({ type: String }) componentId !: string
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) editing !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition !: ViewItem

  button : Button | null = null
  busy = false

  get normalizedIcon () {
    if (!this.button) return
    let icon = this.button.icon || ''
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  async handleClick () {
    if (!this.button || this.busy || this.preview) return
    this.busy = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql` mutation buttonRunHooks($button: JSON, $parameters: JSON, $singular: Boolean) {
          result: buttonRunHooks(button: $button, parameters: $parameters, singular: $singular)
        }`,
        variables: {
          button: this.button,
          parameters: this.parseParameters(),
          singular: true
        }
      })
      await this.handleSuccess(data.result)
    } catch (e) {
      let message = e.message
      if (e.graphQLErrors) message = e.graphQLErrors.map((e : GraphQLError) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: this.button.onErrorMessage || 'Error: ' + message,
        color: 'error',
        timeout: 10000
      })
      console.error(e)
    } finally {
      this.busy = false
    }
  }

  parseParameters (params = this.viewParams) {
    const dstParams = { ...params }
    if (this.button && this.button.parameters) {
      for (const btnParam of this.button.parameters) {
        dstParams[btnParam.parameterName] = params[btnParam.value] || btnParam.value
      }
    }
    return dstParams
  }

  async handleSuccess (result : Record<string, any> | null) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: this.button!.onSuccessMessage || 'Se completó con éxito!',
      timeout: 3000
    })
    // Extract result parameters
    const resultParams = {
      ...this.viewParams,
      ...this.parseParameters(this.viewParams),
      ...result,
      environmentId: this.environmentId
    } as Record<string, any>
    // Redirect to target view
    if (this.button!.url) openLink(this.button!.url, resultParams)
    // Post item data to URL
    if (this.button!.buttonType === 'postItemToUrl' && this.button!.postItemToUrl) {
      const targetUrl = resolveLink(this.button!.postItemToUrl, resultParams)
      await fetch(targetUrl.href, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(resultParams)
      })
    }
    // Set view parameters
    if (this.button!.setViewParameters) {
      const newParams : Record<string, any> = {}
      for (const param of this.button!.setViewParameters) {
        newParams[param.value] = resultParams[param.parameterName]
      }
      this.$emit('setParams', newParams)
    }
  }
}
